<template>
  <div>
    <b-button v-b-modal.modal-component-folder class="grey-button border-0">
      <b-icon icon="folder-plus" class="top--3" aria-hidden="true"></b-icon>
    </b-button>
    <b-modal
      id="modal-component-folder"
      ref="modal-component-folder"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.createItem.folder.insertFolderName') }}</h3>
      </template>

      <template #default>
        <modal-choose-name-content
          :group-id="groupId"
          :item-name.sync="itemName"
          :item-slug.sync="itemSlug"
          :group-slug="groupSlug"
          :child-slugs="childSlugs"
          item-type="folder"
          @enter-pressed="confirmOkModalByEnterKey"
        ></modal-choose-name-content>
      </template>

      <template #modal-footer="{ close }">
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="close()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          :disabled="!isNameSet"
          variant="primary"
          class="p-3"
          block
          @click="handleOk"
        >
          {{ $t('buttons.ok') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ModalChooseNameContent from '@/components/modals/ModalChooseNameContent.vue';
import InsertPlugin from '@/graphQlQueries/mutations/insertPlugin';
import GetFolderSettingsById from '@/graphQlQueries/queries/getFolderSettingsById';
import GetGroupSlugByGroupId from '@/graphQlQueries/queries/getGroupSlugByGroupId';
import GetAllPluginDataOfFolder from '@/graphQlQueries/queries/getAllPluginDataOfFolder';
import SendPublishedDataToServer from '@/mixins/sendPublishedDataToServer';

import InsertNewFolder from '@/graphQlQueries/mutations/insertNewFolder';
import UpdateItemOrderIndex from '@/graphQlQueries/mutations/updateItemOrderIndex';

export default {
  name: 'ModalCreateNewFolder',
  components: { ModalChooseNameContent },
  mixins: [SendPublishedDataToServer],
  props: {
    parentId: {
      type: Number,
      default: null,
    },
    folderProjectPosition: {
      type: Number,
      default: null,
    },
    childSlugs: {
      type: Array,
      required: true,
    },
    groupId: {
      type: Number,
      required: true,
    },
    groupSlug: {
      type: String,
    },
    groupDomain: {
      type: String,
      required: true,
    },
    groupPath: {
      type: String,
      default: null,
    },
    email: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    itemName: null,
    itemSlug: null,
    ciOfParentFolder: null,
    pluginDataOfParentFolder: null,
    languageIdOfParent: null,
  }),
  apollo: {
    groupSlug: {
      query: GetGroupSlugByGroupId,
      variables() {
        return {
          groupId: this.groupId,
        };
      },
      update(data) {
        if (data.groups.length) {
          return data.groups[0].slug;
        }
        return null;
      },
      skip() {
        return !this.groupId;
      },
    },
    ciOfParentFolder: {
      query: GetFolderSettingsById,
      variables() {
        return {
          id: this.parentId,
        };
      },
      update(data) {
        if (data.folders[0]?.language_id) {
          this.languageIdOfParent = data.folders[0].language_id;
        }
        if (data.folders[0]?.corporate_identity) {
          return data.folders[0].corporate_identity;
        }
        return null;
      },
    },
    pluginDataOfParentFolder: {
      query: GetAllPluginDataOfFolder,
      variables() {
        return {
          folderId: this.parentId,
        };
      },
      update(data) {
        return data.plugins;
      },
      skip() {
        return !this.parentId;
      },
    },
  },
  computed: {
    isNameSet() {
      return !!this.itemName;
    },
  },
  methods: {
    confirmOkModalByEnterKey() {
      this.handleOk();
    },
    async handleOk() {
      try {
        const parentSlugPath = (this.$route.params.parametersOfUrl)
          ? `/${this.$route.params.parametersOfUrl}`
          : null;
        const publishedDataWasSuccessfulSend = await this.sendPublishedDataToServer({
          action: 'createFolder',
          data: {
            slug: this.itemSlug,
            parent_slug_path: parentSlugPath,
            group_slug: this.groupSlug,
          },
        });
        if (publishedDataWasSuccessfulSend) {
          const {
            data: {
              insert_folders_one: {
                folder_id,
              },
            },
          } = await this.$apollo.mutate({
            mutation: InsertNewFolder,
            variables: {
              obj: {
                parent_folder_id: this.parentId,
                name: this.itemName,
                order_index: this.folderProjectPosition,
                language_id: this.languageIdOfParent,
                slug: this.itemSlug,
                corporate_identity: this.ciOfParentFolder,
                listed: false,
              },
            },
          });
          // reorder the project to the top of the list
          await this.$apollo.mutate({
            mutation: UpdateItemOrderIndex,
            variables: {
              obj: {
                project_id: null,
                folder_id,
                current_order_index: this.folderProjectPosition,
                new_order_index: 1,
                parent_folder_id: this.parentId,
              },
            },
          });
          if (this.pluginDataOfParentFolder.length > 0) {
            // extend the object with folder and project id
            const pluginData = this.pluginDataOfParentFolder.map((pluginDataItem) => ({
              folder_id,
              project_id: null,
              settings: pluginDataItem.settings,
              handle: pluginDataItem.handle,
              enabled: pluginDataItem.enabled,
            }));
            await this.$apollo.mutate({
              mutation: InsertPlugin,
              variables: {
                object: pluginData,
              },
            });
          }
          // create a new gist entry for folder create
          if (window.gist) {
            window.gist.identify(this.email, { email: this.email, tags: 'folders_trigger' });
          }
          this.$emit('insert-data');
          this.$refs['modal-component-folder'].hide();
        }
      } catch (ex) {
        console.error(ex);
      }
    },
  },
};
</script>
