var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal.modal-component-folder",
              modifiers: { "modal-component-folder": true }
            }
          ],
          staticClass: "grey-button border-0"
        },
        [
          _c("b-icon", {
            staticClass: "top--3",
            attrs: { icon: "folder-plus", "aria-hidden": "true" }
          })
        ],
        1
      ),
      _c("b-modal", {
        ref: "modal-component-folder",
        attrs: {
          id: "modal-component-folder",
          "footer-class": "flex-nowrap",
          "no-close-on-backdrop": "",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$t("modals.createItem.folder.insertFolderName"))
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("modal-choose-name-content", {
                  attrs: {
                    "group-id": _vm.groupId,
                    "item-name": _vm.itemName,
                    "item-slug": _vm.itemSlug,
                    "group-slug": _vm.groupSlug,
                    "child-slugs": _vm.childSlugs,
                    "item-type": "folder"
                  },
                  on: {
                    "update:itemName": function($event) {
                      _vm.itemName = $event
                    },
                    "update:item-name": function($event) {
                      _vm.itemName = $event
                    },
                    "update:itemSlug": function($event) {
                      _vm.itemSlug = $event
                    },
                    "update:item-slug": function($event) {
                      _vm.itemSlug = $event
                    },
                    "enter-pressed": _vm.confirmOkModalByEnterKey
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function({ close }) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        return close()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: {
                      disabled: !_vm.isNameSet,
                      variant: "primary",
                      block: ""
                    },
                    on: { click: _vm.handleOk }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }